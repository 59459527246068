
import { defineComponent,PropType } from "vue";
import ContactForm from "./ContactForm.vue";
import ContactInfo from "./ContactInfo.vue"
import {API_Contact} from "../../types"

export default defineComponent({
  components: { ContactInfo, ContactForm },
  props: {
    contactData: Object as PropType<API_Contact>
  },
});

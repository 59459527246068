
import { defineComponent } from "vue";
import Hero from "@/components/Hero/Hero.vue";
import Contact from "../components/Contact/Contact.vue";
import fetchContact from "../misc/composition/fetchContact";
import LoadingPageAnim from "../components/LoadingPageAnim.vue"
export default defineComponent({
  components: {
    Hero,
    Contact,
    LoadingPageAnim
  },
  setup() {
    const { contact, loading } = fetchContact();
    document.title = "Contact - Coinflip";

    return { contact, loading };
  },
});

import { get } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import { API_Contact } from "../../types";
import { ref } from "vue";
import casinoHelpers from "./casinoHelpers";

export default function () {
  const contact = ref<API_Contact>({} as API_Contact);
  const loading = ref<boolean>(true);

  const fetchContact = (): Promise<void | API_Contact> => {
    return get<API_Contact>(GET.CONTACT).then((result) => {
      loading.value = false;
      contact.value = camelizeKeys(result);
    });
  };

  fetchContact();
  return {
    contact,
    fetchContact,
    loading,
  };
}


import { defineComponent, PropType } from "vue";
import { Link } from "../../types";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false,
    },
    /** USES V-HTML! */
    description: {
      type: String,
      required: false,
    },
    breadcrumbs: {
      type: Object as PropType<Link[]>,
      required: false,
    },
    backgroundImage: {
      type: String,
      required: false,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "#1E1E1E",
    },
  },
  computed: {
    getBackground() {
      return (
        "background-image: url(" +
        this.backgroundImage +
        "); background-color:" +
        this.backgroundColor
      );
    },
  },
});

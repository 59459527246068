
import { ValidateErrorEntity } from "ant-design-vue/lib/form/interface";
import { defineComponent, reactive, ref, toRaw, UnwrapRef } from "vue";
interface FormState {
  name: string;
  email: string;
  content: string;
}
export default defineComponent({
  setup() {
    const formRef = ref();
    const formState: UnwrapRef<FormState> = reactive({
      name: "",
      email: "",
      content: "",
    });
    const rules = {
      name: [{ required: true, trigger: "change" }],
      email: [{ required: true, trigger: "change" }],
      content: [{ required: true, min: 3, trigger: "change" }],
    };

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          console.log('values', formState, toRaw(formState));
        })
        .catch((error: ValidateErrorEntity<FormState>) => {
          console.log('error', error);
        });
    };
    return {
      formState,
      formRef,
      rules,
      onSubmit,
    };
  },
});

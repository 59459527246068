import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-d950f54a")
const _hoisted_1 = { class: "contact-section2 p-0" }
const _hoisted_2 = { class: "p-0 col-info" }
const _hoisted_3 = { class: "contact-info position-absolute" }
const _hoisted_4 = { class: "col-form" }
const _hoisted_5 = { class: "contact-form" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_contact_info = _resolveComponent("contact-info")!
  const _component_contact_form = _resolveComponent("contact-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_contact_info, _normalizeProps(_guardReactiveProps(_ctx.contactData)), null, 16)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_contact_form)
      ])
    ])
  ]))
}
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-b462ed22")
const _hoisted_1 = { class: "heading-title" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "breadcrumb"
}
const _hoisted_5 = { key: 0 }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: "heading text-center",
    style: _normalizeStyle(_ctx.getBackground)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, [
        _renderSlot(_ctx.$slots, "title", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ], {}, true)
      ])
    ]),
    _createElementVNode("p", { innerHTML: _ctx.description }, null, 8, _hoisted_3),
    _renderSlot(_ctx.$slots, "actions", {}, undefined, true),
    (_ctx.breadcrumbs)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (breadcrumb, i) => {
            return (_openBlock(), _createElementBlock("span", { key: breadcrumb }, [
              _createVNode(_component_router_link, {
                to: breadcrumb.href
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(breadcrumb.name), 1)
                ]),
                _: 2
              }, 1032, ["to"]),
              (i !== _ctx.breadcrumbs.length - 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, " > "))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 4))
}